import React, { useState } from "react";
import { Id } from "storefront/lib/Id";
import rotateRight from "storefront/GrailedAPI/v1/Photos/rotateRight";
import rotateLeft from "storefront/GrailedAPI/v1/Photos/rotateLeft";
import { Photo, GrailedAPIPhoto } from "storefront/Photo";

const RIGHT_ARROW = "⟳";
const LEFT_ARROW = "⟲";

type Props = {
  photoId: Id;
  onSuccess:
    | ((coverPhoto: GrailedAPIPhoto) => void)
    | ((coverPhoto: Photo) => void);
  onError: (error: string) => string | null | undefined;
};

const RotateButtons = ({ photoId, onSuccess, onError }: Props) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleRotateLeft = (evt: React.SyntheticEvent<HTMLElement>): void => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDisabled(true);
    rotateLeft(photoId)
      .then(onSuccess)
      .then(() => setIsDisabled(false))
      .catch(onError);
  };

  const handleRotateRight = (evt: React.SyntheticEvent<HTMLElement>): void => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsDisabled(true);
    rotateRight(photoId)
      .then(onSuccess)
      .then(() => setIsDisabled(false))
      .catch(onError);
  };

  return (
    <div className="photo-rotate-buttons-container">
      <button
        type="button"
        onClick={handleRotateLeft}
        disabled={isDisabled}
        title="Rotate Left"
      >
        {LEFT_ARROW}
      </button>
      <button
        type="button"
        onClick={handleRotateRight}
        disabled={isDisabled}
        title="Rotate Right"
      >
        {RIGHT_ARROW}
      </button>
    </div>
  );
};

export default RotateButtons;
