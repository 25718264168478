import { Dispatch } from "redux";
import { User } from "storefront/User";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import {
  AlgoliaInstantSearchListing,
  isAlgoliaInstantSearchListing,
} from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { SectionName } from "storefront/FittingRoom";
import { Id } from "storefront/lib/Id";
import favoriteListing from "storefront/GrailedAPI/v1/Users/Favorites/favoriteListing";
import unfavoriteListing from "storefront/GrailedAPI/v1/Users/Favorites/unfavoriteListing";
import followUser from "storefront/GrailedAPI/v1/Users/Follows/followUser";
import unfollowUser from "storefront/GrailedAPI/v1/Users/Follows/unfollowUser";
import mute from "storefront/GrailedAPI/v1/Listings/mute";
import unmute from "storefront/GrailedAPI/v1/Listings/unmute";
import timeout from "storefront/lib/timeout";
import { Event } from "storefront/Analytics/Event";
import toggleUserFollow from "storefront/Analytics/EventCreators/toggleUserFollow";
import removeFromFavorites from "storefront/Analytics/EventCreators/removeFromFavorites";
import getProductProperties from "storefront/Analytics/getProductProperties";
import {
  FETCH_FITTING_ROOM_ERROR,
  FETCH_FITTING_ROOM_REQUEST,
  FETCH_FITTING_ROOM_SUCCESS,
  FITTING_ROOM_FOLLOW_SELLER,
  FITTING_ROOM_UNFOLLOW_SELLER,
  FAVORITE_LISTING_ERROR,
  FAVORITE_LISTING_REQUEST,
  FAVORITE_LISTING_SUCCESS,
  MUTE_LISTING_REQUEST,
  MUTE_LISTING_SUCCESS,
  NO_MORE_LISTINGS_FOR_FITTING_ROOM,
  UNFAVORITE_LISTING_ERROR,
  UNFAVORITE_LISTING_REQUEST,
  UNFAVORITE_LISTING_SUCCESS,
  UNMUTE_LISTING_REQUEST,
  UNMUTE_LISTING_SUCCESS,
  VIEW_CAPSULES,
  VIEW_GRAILS,
  VIEW_SEARCHES,
  VIEW_SELLERS,
  VIEW_DESIGNERS,
} from "./constants";
import { GetState } from "../GlobalState";
import { ListingAction } from "./listingAction";

type Response = {
  hits: Array<AlgoliaListing>;
};

export function viewGrails() {
  return {
    type: VIEW_GRAILS,
  };
}

export function viewSearches() {
  return {
    type: VIEW_SEARCHES,
  };
}

export function viewSellers() {
  return {
    type: VIEW_SELLERS,
  };
}

export function viewCapsules() {
  return {
    type: VIEW_CAPSULES,
  };
}

export function viewDesigners() {
  return {
    type: VIEW_DESIGNERS,
  };
}

type FetchRequest = {
  type: typeof FETCH_FITTING_ROOM_REQUEST;
  payload: {
    destination: SectionName;
  };
};

export function fetchFittingRoomRequest(
  destination: SectionName,
): FetchRequest {
  return {
    type: FETCH_FITTING_ROOM_REQUEST,
    payload: {
      destination,
    },
  };
}

type SuccessResponse = {
  type: typeof FETCH_FITTING_ROOM_SUCCESS;
  payload: {
    destination: SectionName;
    listings: Array<AlgoliaListing>;
  };
};

export const fetchFittingRoomSuccess = (
  destination: SectionName = "grails",
  response: Response,
): SuccessResponse => ({
  type: FETCH_FITTING_ROOM_SUCCESS,
  payload: {
    destination,
    listings: response.hits,
  },
});

type ErrorResponse = {
  type: typeof FETCH_FITTING_ROOM_ERROR;
  payload: {
    destination: SectionName;
    error: Error;
  };
};

export function fetchFittingRoomError(
  destination: SectionName,
  error: Error,
): ErrorResponse {
  return {
    type: FETCH_FITTING_ROOM_ERROR,
    payload: {
      destination,
      error,
    },
  };
}

type NoMoreListingsResponse = {
  type: typeof NO_MORE_LISTINGS_FOR_FITTING_ROOM;
  payload: {
    viewing: SectionName;
  };
};

export function noMoreListingsForFittingRoom(
  viewing: SectionName,
): NoMoreListingsResponse {
  return {
    type: NO_MORE_LISTINGS_FOR_FITTING_ROOM,
    payload: {
      viewing,
    },
  };
}

type FetchingActions =
  | FetchRequest
  | ErrorResponse
  | SuccessResponse
  | NoMoreListingsResponse;

export function fetchMoreForFittingRoom(destination?: SectionName) {
  return async (dispatch: Dispatch<FetchingActions>, getState: GetState) => {
    const dest = destination || getState().fittingRoom.viewing;
    dispatch(fetchFittingRoomRequest(dest));
    const state = getState().fittingRoom;

    if (state[dest].pager) {
      timeout(state[dest].pager.next()).then(({ done, value }) => {
        if (done) {
          dispatch(noMoreListingsForFittingRoom(dest));
        }

        if (!done && value) {
          if (value.tag === "failure") {
            dispatch(fetchFittingRoomError(dest, value.rejection));
          } else {
            dispatch(fetchFittingRoomSuccess(dest, value));
          }
        }
      });
    }
  };
}

export const removeFromFittingRoom =
  (
    currentUserId: Id,
    listing:
      | AlgoliaInstantSearchListing
      | GrailedAPILightListing
      | AlgoliaListing,
    track: (event: Event) => Event,
    from?: string | null | undefined,
  ) =>
  (dispatch: Dispatch<ListingAction>): Promise<void> => {
    dispatch({
      type: UNFAVORITE_LISTING_REQUEST,
      payload: {
        listingId: listing.id,
      },
    });
    return unfavoriteListing(currentUserId, listing.id)
      .then(() => {
        track(removeFromFavorites(listing, from));
        dispatch({
          type: UNFAVORITE_LISTING_SUCCESS,
          payload: {},
        });
      })
      .catch(() => {
        dispatch({
          type: UNFAVORITE_LISTING_ERROR,
          payload: {
            listingId: listing.id,
          },
        });
      });
  };

export const addToFittingRoom =
  (
    currentUserId: Id,
    track: (event: Event) => Event,
    listing:
      | AlgoliaInstantSearchListing
      | GrailedAPILightListing
      | AlgoliaListing,
    from?: string,
  ) =>
  (dispatch: Dispatch<ListingAction>): Promise<void> => {
    dispatch({
      type: FAVORITE_LISTING_REQUEST,
      payload: {
        listingId: listing.id,
      },
    });
    return favoriteListing(currentUserId, listing.id)
      .then(() => {
        /* eslint-disable no-underscore-dangle */
        const algoliaQueryId =
          isAlgoliaInstantSearchListing(listing) && listing.__queryID
            ? listing.__queryID
            : null;
        /* eslint-enable no-underscore-dangle */
        track({
          object: "product",
          action: "added_to_wishlist",
          properties: {
            ...getProductProperties(listing),
            from,
            algoliaQueryId,
          },
        });
        dispatch({
          type: FAVORITE_LISTING_SUCCESS,
          payload: {},
        });
      })
      .catch(() => {
        dispatch({
          type: FAVORITE_LISTING_ERROR,
          payload: {
            listingId: listing.id,
          },
        });
      });
  };

function muteListingRequest(listingId: Id): ListingAction {
  return {
    type: MUTE_LISTING_REQUEST,
    payload: {
      listingId,
    },
  };
}

function muteListingSuccess(listingId: Id): ListingAction {
  return {
    type: MUTE_LISTING_SUCCESS,
    payload: {
      listingId,
    },
  };
}

export function muteListing(listingId: Id) {
  return (dispatch: Dispatch<ListingAction>) => {
    dispatch(muteListingRequest(listingId));
    return mute(listingId).then(() => dispatch(muteListingSuccess(listingId)));
  };
}

function unmuteListingRequest(listingId: Id): ListingAction {
  return {
    type: UNMUTE_LISTING_REQUEST,
    payload: {
      listingId,
    },
  };
}

function unmuteListingSuccess(listingId: Id): ListingAction {
  return {
    type: UNMUTE_LISTING_SUCCESS,
    payload: {
      listingId,
    },
  };
}

export function unmuteListing(listingId: Id) {
  return (dispatch: Dispatch<ListingAction>) => {
    dispatch(unmuteListingRequest(listingId));
    return unmute(listingId).then(() =>
      dispatch(unmuteListingSuccess(listingId)),
    );
  };
}

export function fittingRoomUnfollowSeller(
  track: (event: Event) => Event,
  seller: User,
  currentUserId: Id,
) {
  unfollowUser(seller.id).then(() => {
    track(
      toggleUserFollow({
        followedUserId: seller.id,
        isFollow: false,
        pageType: "followed_sellers",
        userId: currentUserId,
      }),
    );
  });
  return {
    type: FITTING_ROOM_UNFOLLOW_SELLER,
    payload: {
      seller,
    },
  };
}

export function fittingRoomFollowSeller(
  track: (event: Event) => Event,
  seller: User,
  currentUserId: Id,
) {
  followUser(seller.id).then(() => {
    track(
      toggleUserFollow({
        followedUserId: seller.id,
        isFollow: true,
        pageType: "followed_sellers",
        userId: currentUserId,
      }),
    );
  });
  return {
    type: FITTING_ROOM_FOLLOW_SELLER,
    payload: {
      seller,
    },
  };
}
