import * as React from "react";
import cn from "classnames";

import authWrapper, {
  AuthWrapperProps,
} from "storefront/components/Authentication/authWrapper";
import { AlgoliaInstantSearchListing } from "storefront/components/FiltersInstantSearch/CustomInfiniteHits/NonEmpty";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { GrailedAPIHeavyListing } from "storefront/Listing/GrailedAPIHeavyListing";
import { LegacyListing } from "storefront/Listing";
import addListingToDefaultSavedList from "storefront/GrailedAPI/v1/SavedLists/addListingToDefaultSavedList";
import Bookmark from "storefront/components/Icons/Bookmark";
import Button from "storefront/components/Button";
import removeListingFromDefaultSavedList from "storefront/GrailedAPI/v1/SavedLists/removeListingFromDefaultSavedList";

import styles from "./Button.module.scss";

type OwnProps = {
  className?: string;
  listing:
    | GrailedAPIHeavyListing
    | LegacyListing
    | AlgoliaInstantSearchListing
    | GrailedAPILightListing;
  onChange?: (saved: boolean) => void;
  onClick?: () => void;
};

type Props = OwnProps & AuthWrapperProps;

const SaveListing = ({
  className,
  listing,
  onChange,
  onClick,
  authenticated,
}: Props) => {
  const [isSaved, setIsSaved] = React.useState<boolean>(
    "inSavedList" in listing ? listing.inSavedList : false,
  );

  const toggleIsSaved = () => {
    if (onClick) onClick();

    setIsSaved((prev) => {
      if (onChange) onChange(!prev);

      if (prev) {
        removeListingFromDefaultSavedList(listing.id).then((res) =>
          setIsSaved(res.inSavedList),
        );
      } else {
        addListingToDefaultSavedList(listing.id).then((res) =>
          setIsSaved(res.inSavedList),
        );
      }

      return !prev;
    });
  };

  const title = isSaved ? "Remove from Saved List" : "Add to Saved List";

  return (
    <Button
      className={cn(styles.button, className)}
      title={title}
      type="button"
      variant="unstyled"
      onClick={authenticated(toggleIsSaved, {
        modalTrigger: "save_listing",
        verifications: new Set(["email"]),
      })}
    >
      <Bookmark className={styles.bookmark} isFull={isSaved} />
    </Button>
  );
};

export default authWrapper(SaveListing);
